/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from "shards-react";
import ReactLoading from 'react-loading';

const SidebarActions = (props) => {

  const [loading,setLoading]=React.useState(false)

  return <>
 
    {
     loading ?
     <ReactLoading 
     type="spin"
     color={'blue'} height={'20%'} width={'20%'} 
     />
      :
      <Card small className="mb-3">
       <CardHeader className="border-bottom">
      <h6 className="m-0">{"Actions"}</h6>
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush>
        <ListGroupItem >
          <Button theme="accent" size="sm" className="ml-auto" onClick={()=>{
            
           if(props.service==""){
            alert("Please select service category!")
            return
          }
          if(props.file==""){
            alert("Please upload image!")
            return
          }
              setLoading(true)
              fetch('https://moasarserver.aiodock.com/moasar/subservice',{
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify(props)
              }).then(res => {
                if(res.status== 400){
                  alert('Failed, Server is not responding!');
                }else{
                  alert('Success, Sub Service Updated!');
                  props.reset()
                  setLoading(false)
                }
              })
            
         
          }}>
            <i className="material-icons">file_copy</i> Update
          </Button>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
    </Card>

    }
   
  </>
}

SidebarActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarActions.defaultProps = {
  title: "Actions"
};

export default SidebarActions;
