import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody,CardHeader, FormInput, FormCheckbox } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";


const AddNewSubService = () => {
  const [title, setTitle] = React.useState("")

  const [file, setFile] = useState("")
  const [extension, setExtension] = useState("")


  const [content,setContent]=useState("")

  const [icon,setIcon]=useState("")

  const [service,setService]=useState("")

  const [openCategories,setOpenCategories]=useState(false)

  const toggle=()=>{
    setOpenCategories(!openCategories)
  }

  const [categories,setCategories]=React.useState([])

  const onChangeTitle = (text) => {
    setTitle(text)
  }
  const reset =()=>{
    setTitle("")
    setContent("")
    setIcon("")
    setExtension("")
    setFile("")

  }


  const handlePicture = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('contained-button-file');
    var filePath = fileInput.value;
    var fileArr = filePath.split('.')

    var ext = fileArr[fileArr.length - 1]

    const base64 = await convertBase64(file)
    setExtension(ext)
    setFile(base64)

  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  useEffect(()=>{
    fetch('https://moasarserver.aiodock.com/moasar/service/title',{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res=>res.json()).then(response => {
        setCategories(response)
    })
  },[])


  return <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Add New Sub Service" subtitle="Sub Services" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col lg="9" md="12">

        <Editor onChangeTitle={onChangeTitle} setContent={setContent} title={"Sub Service Title"} />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <Card small className="card-post card-post--1">
        <CardHeader className="border-bottom">
      <h6 className="m-0">{"Service Category"}</h6>
    </CardHeader>
        <CardBody>
                  <Dropdown open={openCategories} toggle={toggle} >
                  <DropdownToggle style={{width:"100%"}}>{service!="" ? service.split('-')[1] : "Select Category"}</DropdownToggle>
                  <DropdownMenu style={{height:"50em"}}>
                    {
                      categories.map((i)=>{
                        return <DropdownItem onClick={()=>{setService(i.id+"-"+i.title)}} >{i.title}</DropdownItem>
                      })
                    }
                  </DropdownMenu>
                </Dropdown>
        </CardBody>
         
        </Card>
        <br />
        <SidebarActions  content={content} title={title} icon={icon} service={service} file={file} extension={extension} reset={reset} subservice={true} />
        <br />
        <input
          className="form-control-alternative"
          id="contained-button-file"
          accept="image/*"
          type="file"

          onChange={(e) => {
            { handlePicture(e) }
          }}
        />
 <br />
 <br />
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${file})` }}
          >


          </div>
          <CardBody>
            <h5 className="card-title">
              <a href="#" className="text-fiord-blue">
                {title}
              </a>
            </h5>
          </CardBody>
        </Card>
       

      </Col>
    </Row>
  </Container>
}

export default AddNewSubService;

