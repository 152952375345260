/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from "shards-react";
import ReactLoading from 'react-loading';

const SidebarActions = (props) => {

  const [loading,setLoading]=React.useState(false)

  return <>
 
    {
     loading ?
     <ReactLoading 
     type="spin"
     color={'blue'} height={'20%'} width={'20%'} 
     />
      :
      <Card small className="mb-3">
       <CardHeader className="border-bottom">
      <h6 className="m-0">{"Actions"}</h6>
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush>
        <ListGroupItem >
          <Button theme="accent" size="sm" className="ml-auto" onClick={()=>{
            if(props.service){
              setLoading(true)
            
              fetch('https://moasarserver.aiodock.com/moasar/service',{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify(props)
              }).then(res => {
                if(res.status== 400){
                  alert('Failed, Server is not responding!');
                }else{
                  alert('Success, Service Published!');
                  props.reset()
                  setLoading(false)
                }
              })
            }else if(props.subservice){
              if(props.file==""){
                alert("Please upload image!")
                return
              }
              if(props.service==""){
                alert("Please select service category!")
                return
              }
             
              setLoading(true)
              
              fetch('https://moasarserver.aiodock.com/moasar/subservice',{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify(props)
              }).then(res => {
                if(res.status== 400){
                  alert('Failed, Server is not responding!');
                }else{
                  alert('Success, Sub-Service Published!');
                  props.reset()
                  setLoading(false)
                }
              })
            }else if(props.childservice){
              if(props.file==""){
                alert("Please upload image!")
                return
              }
              if(props.service==""){
                alert("Please select service category!")
                return
              }
             
              setLoading(true)
              
              fetch('https://moasarserver.aiodock.com/moasar/childservice',{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify(props)
              }).then(res => {
                if(res.status== 400){
                  alert('Failed, Server is not responding!');
                }else{
                  alert('Success, Child Service Published!');
                  props.reset()
                  setLoading(false)
                }
              })
            }else{
              if(props.file==""){
                alert("Please upload image!")
                return
              }
              fetch('https://moasarserver.aiodock.com/moasar/blog',{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify(props)
              }).then(res => {
                if(res.status== 400){
                  alert('Failed, Server is not responding!');
                }else{
                  alert('Success, Blog Published!');
                  props.reset()
                  setLoading(false)
                }
              })
            }
         
          }}>
            <i className="material-icons">file_copy</i> Publish
          </Button>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
    </Card>

    }
   
  </>
}

SidebarActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarActions.defaultProps = {
  title: "Actions"
};

export default SidebarActions;
