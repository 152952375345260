import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Container, Col } from "shards-react";
import {
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

import InnerEditor from './innerEditor'

import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";

const Editor = (props) => {
  

  return <Card small className="mb-3">
    <CardBody>
 
      <Form className="add-new-post">
       
        <FormInput size="lg" className="mb-3" placeholder={"Main Service Title"} value={props.title} onChange={(e)=>{props.onChangeTitle(e.target.value)}}/>
        <InnerEditor setContent={props.setContent} short={props.short} content={props.content} />
        <br />
        <Container style={{
          display:"flex",
          flexDirection:"row"
        }}>
          <Col md={6}>
          <FormInput size="lg" placeholder={"Meta Title"} value={props.metaTitle} onChange={(e)=>{props.onChangeMetaTitle(e.target.value)}}/>
          </Col>
          <Col md={6}>

          <FormInput size="lg" className="mb-3" placeholder={"Meta Keywords"} value={props.metaKeywords} onChange={(e)=>{props.onChangeMetaKeywords(e.target.value)}}/>
        
          </Col>
        
        </Container>
      
        <FormInput size="lg" placeholder={"Meta Description"} value={props.metaDescription} onChange={(e)=>{props.onChangeMetaDescription(e.target.value)}}/>

      </Form>

    </CardBody>
  </Card>
}

export default Editor;
