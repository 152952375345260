export default function() {
  return [
    {
      title: "Add New Service",
      htmlBefore: '<i class="material-icons">add</i>',
      to: "/add-new-service",
    },
    {
      title: "Services",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/services",
    },
    {
      title: "Add New Child Services",
      htmlBefore: '<i class="material-icons">add</i>',
      to: "/add-new-child-services",
    },
    {
      title: "Child Services",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/child-services",
    },
    {
      title: "Add New Sub Service",
      htmlBefore: '<i class="material-icons">add</i>',
      to: "/add-new-subservice",
    },
    {
      title: "Sub Services",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/sub-services",
    },
    {
      title: "Add New Blog",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-blog",
    },
    {
      title: "Blogs",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    }
    
  ];
}
