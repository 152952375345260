import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";

import RangeDatePicker from "../components/common/RangeDatePicker";

import Edit from './Subservice/edit'


const Services = () => {

  const [services, setServices] = React.useState([])
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState([])



  useEffect(() => {
    from.setDate(from.getDate() - 60)
    var obj = {
      from,
      to
    }

    fetch('https://moasarserver.aiodock.com/moasar/subservice/filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(res => res.json()).then(response => {
      setServices(response)
    })

  }, [])
  const closeModal = () => {
    setEdit(false)
  }
  const filter=()=>{
    var obj = {
      from,
      to
    }

    fetch('https://moasarserver.aiodock.com/moasar/subservice/filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(res => res.json()).then(response => {
      setServices(response)
    })

  }

  return <Container fluid className="main-content-container px-4">
    {
      edit ?
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
        :
        null
    }
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Sub Services" subtitle="Sub Services" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">All Sub Services</h6>
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <Col lg="4" md="4" sm="12">  <RangeDatePicker to={to} from={from} setFrom={setFrom} setTo={setTo} /></Col>
                <Col lg="4" md="4" sm="12">   <Button outline theme="accent" size="sm" onClick={() => filter()}>
                  <i className="material-icons">search</i> Search
                </Button></Col>

              </Row>
            </Container>
          </CardHeader>


          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                <th scope="col" className="border-0">
                    Sub Service Name
                  </th>
                  <th scope="col" className="border-0">
                    Service Category
                  </th>
                  <th scope="col" className="border-0">
                    Created Date
                  </th>
                  <th scope="col" className="border-0">
                    Content
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>
                  <th scope="col" className="border-0">
                    Delete
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                  services.map((row) => {
                    return <tr>
                      <td>{row.title}</td>
                      <td>{row.service.split('-')[1]}</td>
                      <td>{new Date(row.date).toDateString()}</td>
                      <td>{row.content.substring(0, 10)}...</td>
                      <td> <i onClick={() => {
                        setEditData(row)
                        setEdit(true)
                     
                      }} className="material-icons">edit</i></td>
                      <td> <i onClick={() => {
                            if (window.confirm("Are you Sure? You want to delete!") == false) {
                              return
                            } 
                               fetch('https://moasarserver.aiodock.com/moasar/subservice',{
                                method: 'DELETE',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                body:JSON.stringify({
                                id: row.id
                                })
                              }).then(res => {
                                if(res.status== 400){
                                  alert('Failed, Server is not responding!');
                                }else{
                                  alert('Success, Service Deleted!');
                                  filter()
                                }
                              })
                      }} className="material-icons">delete</i></td>
                    </tr>
                  })
                }


              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>

  </Container>
}

export default Services;
