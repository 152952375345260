import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";

import RangeDatePicker from "../components/common/RangeDatePicker";

import Edit from './Blog/edit'


const Blog = () => {

  const [blog, setBlog] = React.useState([])
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState([])

  

  useEffect(() => {
    from.setDate(from.getDate() - 60)
    var obj = {
      from,
      to
    }

    fetch('https://moasarserver.aiodock.com/moasar/blog/filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(res => res.json()).then(response => {
      setBlog(response)
    })

  }, [])
  const closeModal = () => {
    setEdit(false)
  }
  const filter=()=>{
    var obj = {
      from,
      to
    }

    fetch('https://moasarserver.aiodock.com/moasar/blog/filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(res => res.json()).then(response => {
      setBlog(response)
    })

  }

  return <Container fluid className="main-content-container px-4">
    {
      edit ?
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
        :
        null
    }
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Manage Blogs" subtitle="Blog" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <Container fluid className="main-content-container px-4">
              <Row noGutters className="page-header py-4">
                <Col lg="4" md="4" sm="12">  <RangeDatePicker to={to} from={from} setFrom={setFrom} setTo={setTo} /></Col>
                <Col lg="4" md="4" sm="12">   <Button outline theme="accent" size="sm" onClick={() => filter()}>
                  <i className="material-icons">search</i> Search
                </Button></Col>

              </Row>
            </Container>
          </CardHeader>


          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Blog Name
                  </th>
                  <th scope="col" className="border-0">
                    Created Date
                  </th>
                  <th scope="col" className="border-0">
                    Content
                  </th>
                  <th scope="col" className="border-0">
                    Edit
                  </th>
                  <th scope="col" className="border-0">
                    Delete
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                  blog.map((row) => {
                    return <tr>
                      <td>{row.title}</td>
                      <td>{new Date(row.date).toDateString()}</td>
                      <td>{row.content.substring(0, 10)}...</td>
                      <td> <i onClick={() => {
                        setEditData(row)
                        setEdit(true)
                     
                      }} className="material-icons">edit</i></td>
                      <td> <i onClick={() => { 
                         if (window.confirm("Are you Sure? You want to delete!") == false) {
                          return
                        } 
                           fetch('https://moasarserver.aiodock.com/moasar/blog',{
                            method: 'DELETE',
                            headers: {
                              'Content-Type': 'application/json'
                            },
                            body:JSON.stringify({
                            id: row.id
                            })
                          }).then(res => {
                            if(res.status== 400){
                              alert('Failed, Server is not responding!');
                            }else{
                              alert('Success, Blog Deleted!');
                              filter()
                            }
                          })
                      }} className="material-icons">delete</i></td>
                    </tr>
                  })
                }


              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>

  </Container>
}

export default Blog;