import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";
import EditComponent from './editComponent'

export default class BasicModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: true };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
   this.props.closeModal()
   this.props.filter()
  }

  render() {
    const { open } = this.state;
    return (
      <div>
        <Modal open={open} toggle={this.toggle} >
          <ModalHeader>Edit Sub Service</ModalHeader>
          <ModalBody>
            <EditComponent editData={this.props.editData} closeEdit={this.toggle}  />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}