import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Container, Col } from "shards-react";
import {
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

import InnerEditor from './innerEditor'

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

const Editor = (props) => {
  

  return <Card small className="mb-3">
    <CardBody>
 
      <Form className="add-new-post">
       
        <FormInput size="lg" className="mb-3" placeholder={props.title} onChange={(e)=>{props.onChangeTitle(e.target.value)}}/>
        {
    props.title=="Main Service Title" 
    ?
    <FormInput size="lg" className="mb-3" placeholder={"Description"} onChange={(e)=>{props.onChangeDescription(e.target.value)}}/>
      
  :
  null      
  }
        <InnerEditor setContent={props.setContent} short={props.short} />
      </Form>
      {
    props.title=="Main Service Title" || props.title=="Blog Title"
    ?
    <>
        <br />
    <Container style={{
      display:"flex",
      flexDirection:"row"
    }}>
      <Col md={6}>
      <FormInput size="lg" placeholder={"Meta Title"} onChange={(e)=>{props.onChangeMetaTitle(e.target.value)}}/>
      </Col>
      <Col md={6}>

       <FormInput size="lg" className="mb-3" placeholder={"Meta Keywords"} onChange={(e)=>{props.onChangeMetaKeywords(e.target.value)}}/>
     
      </Col>
     
    </Container>
   
    <FormInput size="lg" placeholder={"Meta Description"} onChange={(e)=>{props.onChangeMetaDescription(e.target.value)}}/>

  </>
  :
  null
  }
    </CardBody>
  </Card>
}

export default Editor;
