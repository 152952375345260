import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, FormInput } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

const AddNewService = () => {
  const [title, setTitle] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [file, setFile] = useState("")
  const [extension, setExtension] = useState("")

  const [icon,setIcon]=useState("")

  const [metaTitle,setMetatitle]=useState("")

  const [metaDescription,setMetaDescription]=useState("")

  const [metaKeywords,setMetaKeywords]=useState("")

  const [content,setContent]=useState("")

  const onChangeTitle = (text) => {
    setTitle(text)
  }
  const onChangeDescription = (text) => {
    setDescription(text)
  }
  const onChangeMetaTitle = (text) => {
    setMetatitle(text)
  }
  const onChangeMetaDescription = (text) => {
    setMetaDescription(text)
  }
  const onChangeMetaKeywords = (text) => {
    setMetaKeywords(text)
  }
  const reset =()=>{
    setTitle("")
    setFile("")
    setExtension("")
    setMetaDescription("")
    setMetatitle("")
    setContent("")
    setMetaKeywords("")
  }

  const handlePicture = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('contained-button-file');
    var filePath = fileInput.value;
    var fileArr = filePath.split('.')

    var ext = fileArr[fileArr.length - 1]

    const base64 = await convertBase64(file)
    setExtension(ext)
    setFile(base64)

  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }




  return <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Add New Main Service" subtitle="Main Services" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col lg="9" md="12">

        <Editor onChangeTitle={onChangeTitle} description={description} onChangeDescription={onChangeDescription} setContent={setContent} title={"Main Service Title"} onChangeMetaDescription={onChangeMetaDescription} onChangeMetaKeywords={onChangeMetaKeywords} onChangeMetaTitle={onChangeMetaTitle} />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <FormInput size="lg" className="mb-3" placeholder={"Icon Name"} value={icon} onChange={(e)=>{setIcon(e.target.value)}}/>
        <SidebarActions content={content} description={description}  metaTitle={metaTitle} metaDescription={metaDescription} metaKeywords={metaKeywords} title={title} reset={reset} icon={icon} file={file} extension={extension} service={true}/>
        <br />
        <br />
        <input
          className="form-control-alternative"
          id="contained-button-file"
          accept="image/*"
          type="file"

          onChange={(e) => {
            { handlePicture(e) }
          }}
        />
 <br />
 <br />
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${file})` }}
          >


          </div>
         
        </Card>
     

      </Col>
    </Row>
  </Container>
}

export default AddNewService;
