import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewBlog";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import AddNewService from "./views/AddNewService";
import AddNewSubService from "./views/AddNewSubServices";
import SubServices from "./views/SubServices";
import Services from "./views/Services";

import AddNewChildrenService from "./views/AddNewChildrenService";
import ChildService from "./views/ChildServices";



export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/add-new-service" />
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-blog",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/add-new-subservice",
    layout: DefaultLayout,
    component: AddNewSubService
  },
  {
    path: "/add-new-service",
    layout: DefaultLayout,
    component: AddNewService
  },
  {
    path: "/services",
    layout: DefaultLayout,
    component: Services
  },
  {
    path: "/sub-services",
    layout: DefaultLayout,
    component: SubServices
  },
  {
    path: "/add-new-child-services",
    layout: DefaultLayout,
    component: AddNewChildrenService
  },
  {
    path: "/child-services",
    layout: DefaultLayout,
    component: ChildService
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
